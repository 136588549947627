import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/load-testing-with-circleci-meta.jpg'
import { Pagination } from 'swiper';
import { useTypewriter, Cursor } from 'react-simple-typewriter'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


const data = [
  {
    avatar: AVTR1,
    name: "k6 Performance Tests on CircleCI",
    review: "In modern development workflows, performance testing is crucial to ensure that applications handle load efficiently. "
  },
  // {
  //   avatar: AVTR2,
  //   name: "Egor Nesterenko",
  //   review: "I came to Oleksandr with a very speficic requirement which he was able to turn around and fix within a few hours. I was really impressed with the service, thank you so much for helping me."
  // },
]

const Testimonials = () => {
  const { text } = useTypewriter({
    words: ["n Medium"],
    loop: 0,
  });
  return (
    <section id='testimonials'>
      <h5>My blog</h5>
      <h2>O{text}<Cursor /></h2>

      <Swiper className="container testimonials__container"
      modules={[ Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}
>
        {data.map(({avatar, name, review},  index) => {
          return(
            <SwiperSlide key={index} className='testimonial'>
            <div className="client__avatar">
              <img src={avatar}  style={{width: '4rem', height: '100%'}}  alt="Avatar"/>
            </div>
            <h5 className='client__name'>{name}</h5>
              <small className='client__review'>
               {review}
              </small>
            <a href='https://medium.com/@kryo/running-k6-performance-tests-on-circleci-with-cron-scheduling-ef3540c4437b'  rel="noreferrer" target="_blank">Continue reading...</a>  
          </SwiperSlide>
       
          )
        }) }
       
      </Swiper>
    </section>
  )
}

export default Testimonials