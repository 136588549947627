import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'
import { useTypewriter, Cursor } from 'react-simple-typewriter'

const Services = () => {
  const { text } = useTypewriter({
    words: ["ervices"],
    loop: 0,
  });
  return (
    <section id='service'>
      <h5>What I Offer</h5>
      <h2>S{text}<Cursor /></h2>

      <div className="container services__container">
      <article className='service'>
          <div className='service__head'>
            <h3>Security</h3>
          </div>
          <ul className='service__list'>
            <li><BiCheck className='service__list-icon '/>
            <p>Vulnerability Assessment </p>
            </li>
            <li><BiCheck className='service__list-icon '/>
            <p>Dependencies updates </p>
            </li>
            <li><BiCheck className='service__list-icon '/>
            <p>Penetration testing </p>
            </li>
            <li><BiCheck className='service__list-icon '/>
            <p>Security Test Planning and Strategy </p>
            </li>
            <li><BiCheck className='service__list-icon '/>
            <p>Risk Analysis and Mitigation </p>
            </li>
            <li><BiCheck className='service__list-icon '/>
            <p>Incident Response </p>
            </li>
            <li><BiCheck className='service__list-icon '/>
            <p>Compliance and Regulatory Testing </p>
            </li>
            <li><BiCheck className='service__list-icon '/>
            <p>Security Test Automation </p>
            </li>
            <li><BiCheck className='service__list-icon '/>
            <p>Continuous Monitoring </p>
            </li>
          </ul>
        </article>
        {/* Manual testing  */}
        <article className='service'>
          <div className='service__head'>
            <h3>Quality Assurance</h3>
          </div>
          <ul className='service__list'>
            <li><BiCheck className='service__list-icon '/>
            <p>Performance Optimization</p>
            </li>
            <li><BiCheck className='service__list-icon '/>
            <p>Test Automation Strategy</p>
            </li>
            <li><BiCheck className='service__list-icon '/>
            <p>Test Planning and Execution </p>
            </li>
            <li><BiCheck className='service__list-icon '/>
            <p>Test automation framework development </p>
            </li>
            <li><BiCheck className='service__list-icon '/>
            <p>Bug Tracking and Reporting </p>
            </li>
            <li><BiCheck className='service__list-icon '/>
            <p>Mentorship and Leadership</p>
            </li>
            <li><BiCheck className='service__list-icon '/>
            <p>Documentation</p>
            </li>
          </ul>
        </article>
        {/* END OF QA*/}
        <article className='service'>
          <div className='service__head'>
            <h3>SDET</h3>
          </div>
          <ul className='service__list'>
            <li><BiCheck className='service__list-icon '/>
            <p>Test Infrastructure Development </p>
            </li>
            <li><BiCheck className='service__list-icon '/>
            <p>Integration with Development</p>
            </li>
            <li><BiCheck className='service__list-icon '/>
            <p>CI/CD Integration </p>
            </li>
            <li><BiCheck className='service__list-icon '/>
            <p>Test Environment Management </p>
            </li>
            <li><BiCheck className='service__list-icon '/>
            <p>Tool Selection and Implementation</p>
            </li>
          </ul>
        </article>
        {/* END OF SDET */}
     
      </div>
    </section>
  )
}

export default Services