import {useState} from 'react';
import axios from 'axios';
import CGLogo from '../../assets/chatGPT.png';
// import AppLogo from '../../assets/app-logo.png';
import './Chat.css';
import { useTypewriter, Cursor } from "react-simple-typewriter";

function Chat() {
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const { text } = useTypewriter({
    words: ["Ask AI About", "Terminology", "Stack", "Anything"],
    loop: 0,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    
    // communicate with API
    // post input value 'prompt' to API end point 
    // .post("http://localhost:5555/chat", { prompt })
    axios
      .post("/api/chat", { prompt })
      .then((res) => {
        setResponse(res.data.response);
        setLoading(false);
        console.log("Prompt sent to OpenAI:", prompt);
      })
      .catch((err) => {
        console.error(err);
        console.log("Cannot sent shit", prompt);
      });
    
  };

  return (
 
    <div className="wrapper" id="chat">
    <h2>{text}
    <Cursor /></h2>
      {/* <img src={AppLogo} alt="" className="app-logo" />	 */}
      <form onSubmit={handleSubmit}>
        <img src={CGLogo} alt="" className={loading ? 'cg-logo loading' : 'cg-logo'} />
        <input
          type="text"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Type any question..."
        />
        <button type="submit">Ask</button>
      </form>
      <p className="response-area">
        {loading ? 'loading...' : response}
      </p>
</div>

  );
}

export default Chat;